import { InfiniteData, UseInfiniteQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';

import { hasuraPaginator } from 'utils/react-query';

export function useInfiniteQueryItems<Item extends Record<string, unknown>>(
  query: Pick<
    UseInfiniteQueryResult<InfiniteData<{ items: Item[] }>>,
    'data' | 'dataUpdatedAt'
  >,
  uniqKey: keyof Item
): Item[] {
  return useMemo(() => {
    if (query.data) {
      return hasuraPaginator.getUniqueItemsByKey(
        hasuraPaginator.flattenItems(query.data),
        uniqKey
      );
    }
    return [];
  }, [query.dataUpdatedAt, uniqKey]);
}
