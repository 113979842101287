import schedule from 'raf-schd';
import { clamp } from 'ramda';

import { lerp } from 'utils/helpers';
import { clampRatio } from 'utils/numbers';

import { Ratio } from 'types/number';

type PixelValue = number; // should be any pixel value
type Timestamp = number;

const SCROLL_THRESHOLDS = {
  /** Start scrolling when this % away from either the top/bottom of the viewport */
  MIN: 0.15,
  /** Max scrolling speed when this % or closer to the top/bottom of the viewport */
  MAX: 0.05,
};

const SCROLL_SPEED = {
  NONE: 0,
  MIN: 0.01,
  MAX: 16,
};

const SCROLL_DELAY = {
  // Time after starting a drag to start accelerating scroll speed
  ACCELERATE_AFTER_MS: 400,
  // Time after starting a drag to get up to full scroll speed
  END_EASING_AFTER_MS: 2000,
};

type HandleDragScrollOptions = {
  /** Time since drag started. Should use performance.now(), and not Date.now() */
  dragStartTime: Timestamp;
  /** Position of the users pointer, relative to the viewport */
  pointerY: PixelValue;
};

export const handleDragScroll = (options: HandleDragScrollOptions): void => {
  const { dragStartTime, pointerY } = options;

  const distanceToTop = pointerY;
  const distanceToBottom = innerHeight - pointerY;

  const isCloserToTop = distanceToTop < distanceToBottom;

  if (isCloserToTop && window.scrollY === 0) {
    return;
  }

  const thresholds = getDragScrollThresholds(window.innerHeight);

  let scrollBy = SCROLL_SPEED.NONE;

  if (isCloserToTop) {
    scrollBy =
      0 -
      getScrollSpeed({
        dragStartTime,
        distance: distanceToTop,
        thresholds,
      });
  } else {
    scrollBy = getScrollSpeed({
      dragStartTime,
      distance: distanceToBottom,
      thresholds,
    });
  }

  if (scrollBy) {
    scheduleScroll(scrollBy);
  }
};

type DragScrollThresholds = {
  startScrollingAt: PixelValue;
  fastestScrollingAt: PixelValue;
};

const getDragScrollThresholds = (
  windowHeight: number
): DragScrollThresholds => {
  return {
    /** min is the point at which you want to start scrolling, while dragging */
    startScrollingAt: windowHeight * SCROLL_THRESHOLDS.MIN,
    /** max is the point  */
    fastestScrollingAt: windowHeight * SCROLL_THRESHOLDS.MAX,
  };
};

type GetProposedScrollSpeed = {
  distance: PixelValue;
  dragStartTime: Timestamp;
  thresholds: DragScrollThresholds;
};

const getScrollSpeed = ({
  dragStartTime,
  distance,
  thresholds,
}: GetProposedScrollSpeed): PixelValue => {
  const proposedScroll = getScrollFromPosition({
    distance,
    thresholds,
  });

  if (proposedScroll === 0) {
    return SCROLL_SPEED.NONE;
  }

  const scroll = easeScroll({
    dragStartTime,
    proposedScroll,
  });

  return clamp(SCROLL_SPEED.MIN, SCROLL_SPEED.MAX, scroll);
};

type GetScrollFromPositionOptions = {
  distance: PixelValue;
  thresholds: DragScrollThresholds;
};

const getScrollFromPosition = (
  options: GetScrollFromPositionOptions
): PixelValue => {
  const { distance, thresholds } = options;

  // user is dragging too far from edge to trigger scroll
  if (distance > thresholds.startScrollingAt) {
    return 0;
  }

  // use max speed when on or beyond fastestScrollingAt threshold
  if (distance <= thresholds.fastestScrollingAt) {
    return SCROLL_SPEED.MAX;
  }

  if (distance === thresholds.startScrollingAt) {
    return SCROLL_SPEED.MIN;
  }

  const percentageFromFastestScroll = getRatioThroughRange({
    min: thresholds.startScrollingAt,
    max: thresholds.fastestScrollingAt,
    current: distance,
  });

  const percentageFromSlowestScroll = 1 - percentageFromFastestScroll;

  const scroll = lerp(
    SCROLL_SPEED.MIN,
    SCROLL_SPEED.MAX,
    percentageFromSlowestScroll
  );

  return scroll;
};

const getRatioThroughRange = (options: {
  min: PixelValue;
  max: PixelValue;
  current: PixelValue;
}): Ratio => {
  const { min, max, current } = options;

  const scrollRange = min - max;

  if (scrollRange === 0) {
    // Avoids dividing by zero
    return 0;
  }

  const currentInRange = current - max;
  const percentage = currentInRange / scrollRange;
  return percentage;
};

type EaseScrollOptions = {
  dragStartTime: Timestamp;
  proposedScroll: PixelValue;
};

const easeScroll = (options: EaseScrollOptions): PixelValue => {
  const { dragStartTime, proposedScroll } = options;

  const now = performance.now();
  const duration = now - dragStartTime;

  const isAfterEasing = duration >= SCROLL_DELAY.END_EASING_AFTER_MS;
  const isInitiallyProposingScroll =
    duration < SCROLL_DELAY.ACCELERATE_AFTER_MS;

  // After easing is finished, use the proposed scroll speed
  if (isAfterEasing) {
    return proposedScroll;
  }

  // If a scroll is proposed as soon as the drag starts, use the slowest speed
  if (isInitiallyProposingScroll) {
    return SCROLL_SPEED.MIN;
  }

  const betweenAccelerateAtAndStopAtRatio: Ratio = getRatioThroughRange({
    min: SCROLL_DELAY.ACCELERATE_AFTER_MS,
    max: SCROLL_DELAY.END_EASING_AFTER_MS,
    current: duration,
  });

  const ratio = clampRatio(betweenAccelerateAtAndStopAtRatio);

  const ease = easeInOutCubic(Math.abs(1 - ratio));

  return proposedScroll * ease;
};

const scroll = (scrollByY: number) => {
  window.scrollBy({
    top: scrollByY,
  });
};

/** Attempts to update window.scrollBy at most once per animation frame */
const scheduleScroll = schedule(scroll);

/**
 * @see https://easings.net/
 */
function easeInOutCubic(x: number): number {
  return x < 0.5 ? 4 * x * x * x : 1 - Math.pow(-2 * x + 2, 3) / 2;
}
