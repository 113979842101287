import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import * as Generated from 'gql/hasura/queries/home-tab-artworks.generated';

import { HomeTabItem } from 'types/HomeTab';

import { getQueryDataSetterForQueryKey } from './queries/shared';

type HomeTabArtworks = Generated.HomeTabArtworks['artworks'];
type HomeTabArtwork = HomeTabArtworks[number];
export type HomeTabArtworkItem = HomeTabItem & {
  artwork: HomeTabArtwork;
};

const fetchHomeTabArtworks = async (
  homeTabItems: HomeTabItem[]
): Promise<Array<HomeTabArtworkItem>> => {
  const fetchHomeTabArtworks = Generated.useHomeTabArtworks.fetcher({
    ids: homeTabItems.map((item) => item.nftId),
  });
  const response = await fetchHomeTabArtworks();

  if (!response || !response.artworks) return [];

  /**
   * The response is an array of artwork items, but these are returned in the default order from Hasura.
   * We want to return them in the same order as the variables.ids. To enable this, we convert the array
   * to a map, so that we can lookup the artwork item by id in a performant way.
   */
  const artworkMap = response.artworks.reduce(
    (acc, artwork) => {
      return {
        ...acc,
        [artwork.id]: artwork,
      };
    },
    {} as Record<string, HomeTabArtwork>
  );

  // Reorder the artworkMap to match the order of the ids passed in as a variable
  const artworks = homeTabItems.reduce((prev, curr) => {
    const artwork = artworkMap[curr.nftId];

    if (!artwork) return prev;

    return prev.concat({
      ...curr,
      artwork,
    });
  }, [] as Array<HomeTabArtworkItem>);

  return artworks;
};

type HomeTabArtworksVariables = {
  /** either a publicKey or id, depending on the query type */
  uid: string | number;
  homeTabItems: HomeTabItem[];
};

const useHomeTabArtworks = (
  variables: HomeTabArtworksVariables,
  options: Pick<
    UseQueryOptions<Array<HomeTabArtworkItem>, Error>,
    'enabled'
  > = {}
) =>
  useQuery({
    ...options,
    queryKey: useHomeTabArtworks.getKey({ uid: variables.uid }),
    queryFn: () => fetchHomeTabArtworks(variables.homeTabItems),
  });

useHomeTabArtworks.getKey = (
  variables: Pick<HomeTabArtworksVariables, 'uid'>
) => ['HomeTabArtworks', variables];

useHomeTabArtworks.getDataSetter = (
  variables: Pick<HomeTabArtworksVariables, 'uid'>
) =>
  getQueryDataSetterForQueryKey<HomeTabArtworkItem[]>(
    useHomeTabArtworks.getKey(variables)
  );

export default useHomeTabArtworks;
