import { HideIcon, UnhideIcon } from '@f8n/icons';
import { config } from '@f8n-frontend/stitches';
import { useRouter } from 'next/router';
import { useMedia } from 'react-use';

import Icon from 'components/base/IconV2';
import { PopoverMenuOption } from 'components/popover/types';
import { hasToken, isMyAddress } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useNotifications from 'state/stores/notifications';

import HomeIcon from 'assets/icons/home.svg';
import MakeBiggerIcon from 'assets/icons/make-bigger.svg';
import MakeSmallerIcon from 'assets/icons/make-smaller.svg';
import RemoveIcon from 'assets/icons/remove.svg';
import {
  ApiAddHomeItemVariables,
  useAddHomeItem,
} from 'gql/api/mutations/add-home-item.generated';
import {
  ApiDeleteHomeItemVariables,
  useDeleteHomeItem,
} from 'gql/api/mutations/delete-home-item.generated';
import {
  ApiUpdateHomeItemVariables,
  useUpdateHomeItem,
} from 'gql/api/mutations/update-home-item.generated';
import {
  ServerSetArtworkUserVisibilityVariables,
  useSetArtworkUserVisibility,
} from 'gql/server/mutations/set-artwork-user-visibility.generated';
import useSegmentEvent from 'hooks/analytics/use-segment-event';
import useModal from 'hooks/use-modal';

import { ApiErrorData, getApiErrorMessage } from 'types/ApiErrors';
import { DeepNullable, MutationOptions, UnsafeAny } from 'types/utils';

type ToggleArtworkHiddenMenuOptionVariables =
  DeepNullable<ServerSetArtworkUserVisibilityVariables> & {
    publicKey: string;
  };

export const useToggleArtworkHiddenMenuOption = (
  variables: ToggleArtworkHiddenMenuOptionVariables,
  options: MutationOptions<typeof useSetArtworkUserVisibility> = {}
): PopoverMenuOption => {
  const { contractAddress, shouldHide, tokenId } = variables;

  const auth = useAuth();
  const modal = useModal();

  const setArtworkUserVisibility = useSetArtworkUserVisibility(options);

  const enabled =
    isMyAddress(auth, variables.publicKey) &&
    typeof contractAddress === 'string' &&
    typeof shouldHide === 'boolean' &&
    typeof tokenId === 'number';

  return {
    enabled,
    icon: variables.shouldHide ? <HideIcon /> : <UnhideIcon />,
    children: shouldHide ? 'Hide' : 'Unhide',
    onClick: () => {
      if (!enabled) return;

      if (!hasToken(auth)) {
        modal.setModal({ type: 'AUTH_PRE_SIGN' });
        return;
      }

      setArtworkUserVisibility.mutate({
        contractAddress,
        shouldHide,
        tokenId,
      });
    },
  };
};

type AddToProfileHomeAgs = [
  'profile',
  ApiAddHomeItemVariables,
  MutationOptions<typeof useAddHomeItem>,
];

export function useAddToHomeTabOption(
  ...args: AddToProfileHomeAgs
): PopoverMenuOption;

export function useAddToHomeTabOption(
  type: string,
  variables: UnsafeAny,
  options = {}
): PopoverMenuOption {
  const notifications = useNotifications();
  const auth = useAuth();
  const modal = useModal();
  const sendSegmentEvent = useSegmentEvent();
  const router = useRouter();
  const tab = router.query.tab;

  const addToProfileHome = useAddHomeItem<ApiErrorData>(options);

  const onError = (error: ApiErrorData) => {
    notifications.show.error({
      message: getApiErrorMessage(error),
    });
  };

  return {
    enabled: true,
    children: 'Add to Home tab',
    icon: <Icon icon={HomeIcon} />,
    onClick: () => {
      if (!hasToken(auth)) {
        return modal.setModal({ type: 'AUTH_PRE_SIGN' });
      }

      const onSuccess = () => {
        sendSegmentEvent({
          eventName: 'nft_added_to_home',
          payload: { publicKey: auth.publicKey, tab, type },
        });
      };

      const mutateOptions = {
        onError,
        onSuccess,
      };

      if (type === 'profile') {
        addToProfileHome.mutate(
          variables as ApiAddHomeItemVariables,
          mutateOptions
        );
      }
    },
  };
}

export function useDeleteFromHomeTabOption(
  variables: ApiDeleteHomeItemVariables,
  options: MutationOptions<typeof useDeleteHomeItem> = {}
): PopoverMenuOption {
  const notifications = useNotifications();
  const auth = useAuth();
  const modal = useModal();
  const sendSegmentEvent = useSegmentEvent();
  const router = useRouter();
  const tab = router?.query?.tab;

  const deleteFromHomeTab = useDeleteHomeItem<ApiErrorData>(options);

  return {
    enabled: true,
    children: 'Remove from Home tab',
    icon: <Icon icon={RemoveIcon} />,
    onClick: () => {
      if (!hasToken(auth)) {
        return modal.setModal({ type: 'AUTH_PRE_SIGN' });
      }

      deleteFromHomeTab.mutate(variables, {
        onError(error) {
          notifications.show.error({
            message: getApiErrorMessage(error),
          });
        },
        onSuccess: () => {
          sendSegmentEvent({
            eventName: 'nft_removed_from_home',
            payload: { publicKey: auth.publicKey, tab },
          });
        },
      });
    },
  };
}

export function useToggleHomeTabItemSizeOption(
  variables: ApiUpdateHomeItemVariables,
  options: MutationOptions<typeof useUpdateHomeItem> = {}
): PopoverMenuOption {
  const notifications = useNotifications();
  const auth = useAuth();
  const modal = useModal();
  const isMobile = !useMedia(config.media.bp0);

  const updateHomeItem = useUpdateHomeItem<ApiErrorData>(options);

  const shouldIncreaseSize = variables.size === 'LARGE';
  return {
    enabled: !isMobile,
    children: shouldIncreaseSize ? 'Enlarge' : 'Shrink',
    icon: <Icon icon={shouldIncreaseSize ? MakeBiggerIcon : MakeSmallerIcon} />,
    onClick: () => {
      if (!hasToken(auth)) {
        return modal.setModal({ type: 'AUTH_PRE_SIGN' });
      }

      updateHomeItem.mutate(variables, {
        onError(error) {
          notifications.show.error({
            message: getApiErrorMessage(error),
          });
        },
      });
    },
  };
}
