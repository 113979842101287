import { styled } from '@f8n-frontend/stitches';

import { truncateAfterWordsIfNeeded } from 'utils/helpers';

import { UnsafeAny } from 'types/utils';

import Markdown from './Markdown';
import Text from './Text';

interface MarkdownTextProps {
  children: string;
  css?: UnsafeAny;
  truncate?: {
    maxWords: number;
    onReadMore: () => void;
  };
}

export default function MarkdownText(props: MarkdownTextProps) {
  const { children, css, truncate: readMoreConfig } = props;

  const truncate = readMoreConfig
    ? truncateAfterWordsIfNeeded(children, readMoreConfig.maxWords)
    : null;

  return (
    <Text
      css={{
        fontSize: '$1',
        lineHeight: '$3',
        wordBreak: 'break-word',
        position: 'relative',
        color: '$black70',
        '@bp1': {
          fontSize: '$2',
        },
        a: {
          color: 'inherit',
        },
        '& p': {
          marginBottom: '1rem',
        },
        '& p:empty': {
          display: 'none',
        },
        '& p:last-of-type': {
          marginBottom: 0,
          display: truncate ? 'inline' : 'block',
        },
        ...css,
      }}
    >
      <Markdown>{truncate?.isTruncated ? truncate.text : children}</Markdown>
      {readMoreConfig && truncate?.isTruncated && (
        <>
          {' '}
          <ReadMore as="button" onClick={readMoreConfig.onReadMore}>
            Read more
          </ReadMore>
        </>
      )}
    </Text>
  );
}

const ReadMore = styled(Text, {
  background: 'none',
  border: '0px',
  display: 'inline',
  fontSize: 'inherit',
  fontWeight: '$semibold',
  height: 'inherit',
  lineHeight: 'inherit',
  padding: '0px',
  position: 'relative',
  verticalAlign: 'baseline',
  cursor: 'pointer',

  '@hover': {
    '&:hover': {
      color: '$black80',
    },
  },

  '&:focus': {
    color: '$black80',
    outline: 'none',
  },
});
ReadMore.defaultProps = {
  color: 'strong',
};
