import { styled } from '@f8n-frontend/stitches';
import { ReactNode } from 'react';

import Button from 'components/base/Button';
import Checkbox from 'components/base/Checkbox';

import { UnsafeAny } from 'types/utils';

const CheckboxWrapper = styled('label', Button, {
  textAlign: 'unset',
  borderRadius: '$2',
  alignItems: 'flex-start',
  defaultVariants: {
    variant: 'outline',
  },
  compoundVariants: [
    {
      size: 1,
      css: {
        height: 'auto',
        padding: '$5',
        '@bp1': {
          padding: '$7',
        },
      },
    },
  ],
});

interface BlockCheckboxProps {
  name: string;
  checked: boolean;
  onCheckedChange: (arg0: boolean) => void;
  children: ReactNode;
  css?: UnsafeAny;
}

export default function BlockCheckbox(props: BlockCheckboxProps) {
  const { onCheckedChange, checked, name, children, css } = props;
  return (
    <CheckboxWrapper css={css} htmlFor={name}>
      <div>
        <Checkbox
          checked={checked}
          id={name}
          onCheckedChange={onCheckedChange}
        />
      </div>
      {children}
    </CheckboxWrapper>
  );
}
