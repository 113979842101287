import { head } from 'ramda';

import { formatETHWithSuffix } from 'utils/formatters';
import {
  isAllTrue,
  isAnyTrue,
  isEmptyOrNil,
  isNumber,
  isValueInArray,
  notEmptyOrNil,
} from 'utils/helpers';
import { areKeysEqual } from 'utils/users';

import { AlgoliaArtwork, AlgoliaAuctionStatus } from 'types/Algolia';
import { MarketAvailability } from 'types/MarketAvailability';
import { NftSortOrder } from 'types/Nft';
import { ActiveOffer } from 'types/Offer';

import ArtworkMetaGeneric, {
  ArtworkMetaLiveAuction,
  ArtworkCardMetaEndedAuction,
} from './meta/ArtworkCardMetaGeneric';

type MarketWithPrice = {
  type: string;
  value: number;
};
interface ArtworkCardAlgoliaMarketProps {
  auction: AlgoliaArtwork['auction'];
  artwork: AlgoliaArtwork;
  owner: AlgoliaArtwork['owner'];
  marketAvailability: MarketAvailability | null;
  sortOrder: NftSortOrder;
}

export default function ArtworkCardAlgoliaMarket(
  props: ArtworkCardAlgoliaMarketProps
) {
  const { auction, artwork, owner, marketAvailability, sortOrder } = props;

  // @ts-expect-error null-checks
  const endsAt = parseUnixToIso(auction?.endsAt);

  const isCreatorOwner = areKeysEqual([
    owner?.publicKey,
    artwork?.creator?.publicKey,
  ]);

  const activeOffer = getActiveOffer(artwork);

  // available market types (minus HAS_ACTIVE_OFFER)

  const hasMarketAvailability = marketAvailability
    ? isValueInArray(marketAvailability, [
        'HAS_ACTIVE_BUY_NOW',
        'LIVE_AUCTION',
        'RESERVE_NOT_MET',
      ])
    : false;

  // get all the market prices and sort them lowest to highest
  // @ts-expect-error null-checks
  const allMarketPrices: MarketWithPrice[] = [
    { type: 'auction', value: auction?.currentPrice },
    { type: 'buy-now', value: artwork?.latestBuyNow?.amountInETH },
  ]
    .filter((market) => isNumber(market.value))
    .sort((a, b) =>
      // @ts-expect-error null-checks
      sortOrder === 'PRICE_DESC' ? b.value - a.value : a.value - b.value
    );

  // get the first one
  // this will be lowest available price unless sort is PRICE_DESC when it will be highest
  const displayPrice = head(allMarketPrices);

  // return true if there’s no marketAvailability and a lowest market price
  // TODO: tidy up this logic once we settle on a pattern we like
  const canRenderLowestPrice = isAllTrue([
    artwork.availability !== 'LIVE_AUCTION',
    auction?.status !== 'ENDED',
    !marketAvailability,
    displayPrice,
    isAnyTrue([sortOrder === 'PRICE_ASC', sortOrder === 'PRICE_DESC']),
  ]);

  const auctionPrice = formatETHWithSuffix(auction?.currentPrice);
  // @ts-expect-error null-checks
  const buyNowPrice = formatETHWithSuffix(artwork?.latestBuyNow?.amountInETH);
  const lastSoldPrice = formatETHWithSuffix(artwork?.lastSalePriceInETH);

  if (canRenderLowestPrice) {
    return (
      <ArtworkMetaGeneric
        // @ts-expect-error null-checks
        label={
          // @ts-expect-error null-checks
          displayPrice.type === 'buy-now'
            ? 'Buy Now'
            : getAuctionLabel(auction?.status)
        }
        owner={owner}
        // @ts-expect-error null-checks
        value={formatETHWithSuffix(displayPrice.value)}
        isCreatorOwner={isCreatorOwner}
        activeOffer={activeOffer}
      />
    );
  }

  if (
    (!hasMarketAvailability && artwork.availability === 'LIVE_AUCTION') ||
    marketAvailability === 'LIVE_AUCTION'
  ) {
    return (
      <ArtworkMetaLiveAuction
        label="Current bid"
        value={auctionPrice}
        // @ts-expect-error null-checks
        endsAt={endsAt}
      />
    );
  }

  if (
    (!hasMarketAvailability &&
      artwork.marketAvailability?.includes('HAS_ACTIVE_BUY_NOW')) ||
    marketAvailability === 'HAS_ACTIVE_BUY_NOW'
  ) {
    return (
      <ArtworkMetaGeneric
        label="Buy Now"
        owner={owner}
        value={buyNowPrice}
        isCreatorOwner={isCreatorOwner}
        activeOffer={activeOffer}
      />
    );
  }

  if (
    (!hasMarketAvailability && auction?.status === 'OPEN') ||
    marketAvailability === 'RESERVE_NOT_MET'
  ) {
    return (
      <ArtworkMetaGeneric
        label="Reserve"
        owner={owner}
        value={auctionPrice}
        isCreatorOwner={isCreatorOwner}
        activeOffer={activeOffer}
      />
    );
  }

  if (auction?.status === 'ENDED') {
    return (
      <ArtworkCardMetaEndedAuction label="Winning bid" value={auctionPrice} />
    );
  }

  const hasLastSoldPrice = !isEmptyOrNil(artwork?.lastSalePriceInETH);
  if (hasLastSoldPrice) {
    return (
      <ArtworkMetaGeneric
        label="Last sold"
        owner={owner}
        value={lastSoldPrice}
        isCreatorOwner={isCreatorOwner}
        activeOffer={activeOffer}
        isSecondary
      />
    );
  }

  return owner || activeOffer ? (
    <ArtworkMetaGeneric
      owner={owner}
      activeOffer={activeOffer}
      isCreatorOwner={isCreatorOwner}
    />
  ) : null;
}

// algolia dates are formatted as unix epoch (numbers)
// the artwork card supports dates in iso string format
function getActiveOffer(artwork: AlgoliaArtwork): ActiveOffer | undefined {
  const latestOffer = artwork?.latestOffer;

  if (latestOffer?.status === 'HIGHEST') {
    return {
      amountInETH: latestOffer.amountInETH,
      // therefore we need to convert them into iso strings
      expiresAt: parseUnixToIso(latestOffer.expiresAt),
    } as ActiveOffer;
  }

  return undefined;
}

const parseUnixToIso = <T extends string | number>(date: T) => {
  return notEmptyOrNil(date)
    ? new Date(Number(date) * 1000).toISOString()
    : null;
};

const getAuctionLabel = (status: AlgoliaAuctionStatus) => {
  if (status === 'ENDED') {
    return 'Winning bid';
  } else if (status === 'OPEN') {
    return 'Reserve';
  } else if (status === 'FINALIZED') {
    return 'Last sold';
  } else {
    return null;
  }
};
