import { useQuery, UseQueryOptions } from '@tanstack/react-query';

import {
  ApiHomeByAccountAddressVariables,
  useHomeByAccountAddress,
} from 'gql/api/queries/home-by-account-address.generated';

import { HomeTabItem } from 'types/HomeTab';

import { getQueryDataSetterForQueryKey } from './queries/shared';

type HomeTabItems = HomeTabItem[];

export type GetHomeTabItemsVariables = {
  type: 'PROFILE';
} & ApiHomeByAccountAddressVariables;

export const getHomeTabItems = async (
  variables: GetHomeTabItemsVariables
): Promise<HomeTabItems> => {
  const getHomeByAccountAddress = useHomeByAccountAddress.fetcher(variables);
  const response = await getHomeByAccountAddress();

  if (!response || !response.home) return [];
  return response.home.items;
};

const getHomeTabItemsQueryKey = (variables: GetHomeTabItemsVariables) => [
  'HomeTabItems',
  variables,
];

export const useHomeTabItems = (
  variables: GetHomeTabItemsVariables,
  options: Pick<
    UseQueryOptions<
      HomeTabItems,
      Error,
      HomeTabItems,
      ReturnType<typeof getHomeTabItemsQueryKey>
    >,
    'enabled' | 'placeholderData' | 'refetchOnMount' | 'refetchOnWindowFocus'
  > = {}
) => {
  return useQuery({
    ...options,
    queryKey: useHomeTabItems.getKey(variables),
    queryFn: () => getHomeTabItems(variables),
  });
};

useHomeTabItems.getKey = getHomeTabItemsQueryKey;

useHomeTabItems.getDataSetter = (variables: GetHomeTabItemsVariables) =>
  getQueryDataSetterForQueryKey<HomeTabItems>(
    useHomeTabItems.getKey(variables)
  );

useHomeTabItems.getApi = (variables: GetHomeTabItemsVariables) => {
  return {
    key: useHomeTabItems.getKey(variables),
    set: useHomeTabItems.getDataSetter(variables),
  };
};
