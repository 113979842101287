import { MinimalRect } from './types';

type IsCollidingOptions = {
  draggedItemRect: MinimalRect;
  itemRect: MinimalRect;
};

const QUARTER = 0.25;
const HALF = 0.5;

function isColliding(options: IsCollidingOptions) {
  const { draggedItemRect, itemRect } = options;

  /**
   * this works because large items are double the width of small items PLUS
   * the gap between the small items.
   */
  const isSmallOverBig = itemRect.width / 2 > draggedItemRect.width;

  const HALF_WIDTH = itemRect.width * HALF;
  const HALF_HEIGHT = itemRect.height * HALF;
  const QUARTER_HEIGHT = itemRect.height * QUARTER;

  const TARGET_POINT_X = itemRect.x + HALF_WIDTH;
  const TARGET_POINT_Y =
    itemRect.y + (isSmallOverBig ? QUARTER_HEIGHT : HALF_HEIGHT);

  const draggedCardLeft = draggedItemRect.x;
  const draggedCardRight = draggedCardLeft + draggedItemRect.width;
  const draggedCardTop = draggedItemRect.y;
  const draggedCardBottom = draggedCardTop + draggedItemRect.height;

  const collidingLeft = draggedCardLeft < TARGET_POINT_X;
  const collidingRight = draggedCardRight > TARGET_POINT_X;

  const collidingTop = draggedCardTop < TARGET_POINT_Y;
  const collidingBottom = draggedCardBottom > TARGET_POINT_Y;

  const isColliding =
    collidingLeft && collidingRight && collidingTop && collidingBottom;

  return isColliding;
}

type ComparePositionsOptions = {
  draggedIndex: number;
  draggedItemRect: MinimalRect;
  positions: MinimalRect[];
  onCollision(index: number): void;
};

export function compareAllPositions(options: ComparePositionsOptions) {
  const { draggedIndex, draggedItemRect, positions, onCollision } = options;

  for (const [key, position] of Object.entries(positions)) {
    const index = Number(key);

    if (index === draggedIndex) {
      continue;
    }

    if (isColliding({ draggedItemRect, itemRect: position })) {
      onCollision(index);
      break;
    }
  }
}
