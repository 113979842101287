import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import Button, { ButtonProps } from 'components/base/Button';

export interface FormikSubmitButtonProps {
  label: string;
  submittingLabel: string;
  submittedLabel: string;
  disabled?: boolean;
  size?: ButtonProps['size'];
  variant?: ButtonProps['variant'];
}

export default function FormikSubmitButton(props: FormikSubmitButtonProps) {
  const {
    label,
    submittingLabel,
    submittedLabel,
    disabled,
    size = 2,
    variant = 'primary',
  } = props;

  const { isSubmitting, isValid, status } = useFormikContext();

  const [isSubmitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (status?.formSubmitted && isValid) {
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
      }, 10000);
    }
  }, [isValid, status]);

  const isDisabled = isSubmitting || isSubmitted || !isValid || disabled;

  return (
    <Button.Loader
      disabled={isDisabled}
      isLoading={isSubmitting}
      loadingLabel={submittingLabel}
      size={size}
      type="submit"
      variant={variant}
      width="full"
    >
      {isSubmitted ? submittedLabel : label}
    </Button.Loader>
  );
}
