import { ApiGraphQLError } from './gql';

export interface ApiErrorData {
  response: {
    errors: ApiGraphQLError[];
  };
}

export const getApiErrorMessage = (data: ApiErrorData) => {
  if (data.response.errors?.[0]?.message) {
    return data.response.errors[0].message;
  } else {
    return 'Failed, try again';
  }
};
