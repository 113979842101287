import { styled } from '@f8n-frontend/stitches';
import { useField } from 'formik';

import Box from 'components/base/Box';
import Skeleton from 'components/base/Skeleton';
import Text from 'components/base/Text';
import Label from 'components/forms/Label';
import BlockCheckbox from 'components/forms/fields/BlockCheckbox';

const Sub = styled(Text, {});

Sub.defaultProps = {
  size: 1,
  color: 'dim',
  lineHeight: 2,
  weight: 'regular',
};

interface CheckboxAndWrapperProps {
  name: string;
  label: string;
  description: string;
}

export default function CheckboxAndWrapper(props: CheckboxAndWrapperProps) {
  const { label, name, description } = props;
  const [field, , helpers] = useField<boolean>({ name, type: 'checkbox' });
  return (
    <BlockCheckbox
      name={label}
      checked={field.checked || false}
      onCheckedChange={(isChecked) => helpers.setValue(isChecked)}
    >
      <Box css={{ marginLeft: '$5', '@bp1': { marginLeft: '$7' } }}>
        <Label
          as="div"
          css={{ fontSize: '$3', marginBottom: '$2', display: 'block' }}
        >
          {label}
        </Label>
        <Sub>{description}</Sub>
      </Box>
    </BlockCheckbox>
  );
}

CheckboxAndWrapper.Skeleton = function CheckboxAndWrapperSkeleton() {
  return (
    <Skeleton.Block
      css={{ width: '100%', height: 142, padding: '$7', display: 'flex' }}
      variant="outline"
    >
      <Skeleton.Block
        css={{
          width: 24,
          height: 24,
        }}
      />
      <Box
        css={{
          marginLeft: '$7',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <Skeleton.Block
          css={{
            width: 170,
            height: 24,
            marginBottom: '$2',
          }}
        />
        <Skeleton.Block
          css={{
            width: '100%',
            height: 14,
            marginBottom: '$1',
          }}
        />
        <Skeleton.Block
          css={{
            width: '70%',
            height: 14,
          }}
        />
      </Box>
    </Skeleton.Block>
  );
};
