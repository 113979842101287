import { useRouter } from 'next/router';

import {
  useAddToHomeTabOption,
  useDeleteFromHomeTabOption,
} from 'components/dropdown/artwork';
import { PopoverMenuOption } from 'components/popover/types';
import { hasPublicKey } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useNotifications from 'state/stores/notifications';

import useSegmentEvent from 'hooks/analytics/use-segment-event';

import { HomeTabCurationProps } from 'types/HomeTab';

type UseHomeTabCurationBaseOptions = HomeTabCurationProps & {
  nftId: string;
  showViewHomeTabButton?: boolean;
};

type UseHomeTabCurationOptions = {
  type: 'profile';
} & UseHomeTabCurationBaseOptions;

export const useHomeTabCurationOption = (
  options: UseHomeTabCurationOptions
): PopoverMenuOption | undefined => {
  const {
    canCurateHomeTab,
    homeTabCuration,
    nftId,
    showViewHomeTabButton = true,
  } = options;
  const notifications = useNotifications();
  const sendSegmentEvent = useSegmentEvent();
  const router = useRouter();
  const auth = useAuth();
  const publicKey = hasPublicKey(auth) && auth.publicKey;
  const tab = router?.query?.tab;

  const viewHomeTabAction = showViewHomeTabButton
    ? {
        altText: 'View home tab',
        cta: 'View',
        onAction: homeTabCuration.viewHomeTab,
      }
    : undefined;

  const onAddSuccess = () => {
    if (homeTabCuration.onAddToHomeTab) {
      homeTabCuration.onAddToHomeTab();
    }
    notifications.show.success({
      action: viewHomeTabAction,
      message: 'Added to Home tab',
    });
    sendSegmentEvent({
      eventName: 'nft_added_to_home',
      payload: { publicKey, tab },
    });
  };

  const addToHomeTabOption = useAddToHomeTabOption(
    'profile',
    { nftId },
    { onSuccess: onAddSuccess }
  );

  const id = homeTabCuration.getHomeTabItemId(nftId);

  const removeFromHomeTabOption = useDeleteFromHomeTabOption(
    { id },
    {
      onMutate: (variables) => {
        if (homeTabCuration.onRemoveFromHomeTab) {
          homeTabCuration.onRemoveFromHomeTab(variables.id);
        }
      },
      onSuccess: () => {
        notifications.show.success({
          message: 'Removed from Home tab',
          action: viewHomeTabAction,
        });

        sendSegmentEvent({
          eventName: 'nft_removed_from_home',
          payload: { publicKey, tab },
        });
      },
    }
  );

  if (!canCurateHomeTab) return;

  return homeTabCuration?.isOnHomeTab(nftId)
    ? removeFromHomeTabOption
    : addToHomeTabOption;
};
