import { HomeTabItem } from 'types/HomeTab';
import { NftFilters } from 'types/Nft';

export const deleteItemByIdFromHomeTabItems = <Item extends HomeTabItem>(
  id: string,
  items: Item[] | undefined
): Item[] => {
  if (!items) return [];
  return items.filter((item) => item.id !== id);
};

export const changeHomeTabItemSize = <Item extends HomeTabItem>(
  id: string,
  size: Item['size'] = 'SMALL',
  items: Item[] = []
): Item[] => {
  if (!items) return [];
  return items.map((item) => {
    return item.id === id ? { ...item, size } : { ...item, size: 'SMALL' };
  });
};

export const changeHomeTabItemPosition = <Item extends HomeTabItem>(
  id: string,
  position: number,
  items: Item[] = []
): Item[] => {
  if (!items) return [];

  const nextArtworks = [...items];
  const movedItemIndex = nextArtworks.findIndex((item) => item.id === id);

  if (movedItemIndex !== -1) {
    const removedItems = nextArtworks.splice(movedItemIndex, 1);
    nextArtworks.splice(position, 0, removedItems[0] as Item);
  }

  return nextArtworks;
};

export const getCollectionAddressFilters = (
  filters: NftFilters
): string[] | null => {
  if (!filters || !filters.collections) {
    return null;
  }
  return Object.keys(filters.collections).filter(
    (collection) => filters.collections[collection]
  );
};

type HomeTabNfts = HomeTabNft[];

interface HomeTabNft {
  nftId: string;
  id: string;
}

export const getHomeTabNftIdMap = (
  data: HomeTabNfts | undefined
): Record<string, string> => {
  if (!data) return {};
  return data.reduce((prev, curr) => {
    return {
      ...prev,
      [curr.nftId]: curr.id,
    };
  }, {});
};
