import { onGrid } from '@f8n/tokens';
import { styled } from '@f8n-frontend/stitches';
import type { Address } from 'viem';

import Avatar from 'components/base/Avatar';
import Box from 'components/base/Box';
import ButtonWithCount from 'components/base/ButtonWithCount';
import Checkbox from 'components/base/Checkbox';
import CollectionLogo from 'components/base/CollectionLogo';
import Flex from 'components/base/Flex';
import Text from 'components/base/Text';
import { CollectionContractText } from 'components/collections/ContractPill';

import { formatInteger } from 'utils/formatters';
import { getUsernameOrTruncatedAddress, hasUsername } from 'utils/helpers';

type BaseMultiSelectOptionProps = {
  checked: boolean;
  count?: number;
  /** should be a number from 0-1 */
  percentage?: number;
  onCheckedChange(checked: boolean): void;
};

type MultiSelectOptionProps = BaseMultiSelectOptionProps &
  (
    | { label: string }
    | {
        collection: {
          collectionImageUrl?: string;
          name: string;
          symbol?: string;
        };
      }
    | {
        creator: {
          name?: string;
          profileImageUrl?: string;
          publicKey: Address;
          username?: string;
        };
      }
  );

function toId(label: string) {
  return label.toLowerCase().replace(' ', '-');
}

export default function MultiSelectOption(props: MultiSelectOptionProps) {
  const { count, checked, onCheckedChange, percentage } = props;

  const disabled = count === 0;
  const getContent = () => {
    if ('collection' in props) {
      const { collectionImageUrl, name, symbol } = props.collection;

      return (
        <Flex css={{ alignItems: 'center' }}>
          <CollectionLogo
            css={{ marginRight: '$4' }}
            imageUrl={collectionImageUrl}
            size={3}
          />
          <Flex css={{ minWidth: 0, flexDirection: 'column' }}>
            <LabelText
              size={2}
              css={{
                color: '$black100',
                marginBottom: symbol ? '$1' : 0,
              }}
              weight="medium"
            >
              {name}
            </LabelText>
            {symbol && (
              <CollectionContractText css={{ fontSize: '$0' }}>
                {symbol}
              </CollectionContractText>
            )}
          </Flex>
        </Flex>
      );
    }

    if ('creator' in props) {
      const { name, profileImageUrl } = props.creator;

      const usernameOrTruncatedAddress = getUsernameOrTruncatedAddress(
        props.creator
      );
      const userHasUsername = hasUsername(props.creator);

      return (
        <Flex css={{ alignItems: 'center' }}>
          <Avatar
            css={{ marginRight: '$4' }}
            imageUrl={profileImageUrl}
            size={3}
          />
          <Flex css={{ flexDirection: 'column' }}>
            {name && (
              <LabelText
                size={2}
                css={{
                  color: '$black100',
                  marginBottom: '$1',
                }}
                weight="medium"
              >
                {name}
              </LabelText>
            )}
            <Text
              css={{
                fontFamily: userHasUsername ? '$body' : '$mono',
              }}
              color={userHasUsername ? 'dim' : undefined}
              size={userHasUsername ? 2 : name ? 1 : 2}
              weight={userHasUsername ? 'semibold' : 'regular'}
            >
              {/* TODO: fix cropped text below username (g's get cropped) */}
              {usernameOrTruncatedAddress}
            </Text>
          </Flex>
        </Flex>
      );
    }

    return (
      <LabelText
        size={{ '@initial': 1, '@bp2': 2 }}
        css={{
          color: checked ? '$black100' : '$black70',
        }}
        weight="medium"
      >
        {props.label}
      </LabelText>
    );
  };

  const getId = () => {
    if ('collection' in props) {
      // @ts-expect-error null-checks
      return toId(props.collection.symbol);
    }

    if ('creator' in props) {
      return toId(props.creator.publicKey);
    }

    return toId(props.label);
  };

  const id = getId();

  return (
    <Label
      as="label"
      css={{
        minHeight:
          'collection' in props || 'creator' in props ? onGrid(17) : 'auto',
      }}
      disabled={disabled}
      htmlFor={id}
    >
      <Flex css={{ minWidth: 0, alignItems: 'center', marginRight: '$4' }}>
        <Box css={{ flexShrink: 0 }}>
          <Checkbox
            checked={checked}
            disabled={disabled}
            id={id}
            onCheckedChange={onCheckedChange}
          />
        </Box>
        <Content>{getContent()}</Content>
      </Flex>
      <Flex css={{ alignItems: 'center' }}>
        {percentage ? (
          <Percentage>{`${(percentage * 100).toFixed(2)}%`}</Percentage>
        ) : null}
        {count ? (
          <Count aria-label={`${count} items`}>{formatInteger(count)}</Count>
        ) : null}
      </Flex>
    </Label>
  );
}

const Percentage = styled(Text, {
  marginRight: '$3',
});

Percentage.defaultProps = {
  size: 0,
  color: 'dim',
  weight: 'medium',
};

const Count = styled(ButtonWithCount.Count, {
  height: '24px',
  minWidth: '42px',
  textAlign: 'center',
  lineHeight: '24px',
  fontSize: '$0',
  paddingX: '10px',
  fontWeight: '$semibold',
  backgroundColor: '$black10',
  transition: 'background-color $1 $ease, color $1 $ease',
});

const LabelText = styled(Text, {
  truncate: true,
});

const Content = styled('div', {
  minWidth: 0,
  marginLeft: '$3',
});

const Label = styled(Flex, {
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$2',
  borderRadius: '$2',
  cursor: 'pointer',
  transition: 'background-color $1 $ease, color $1 $ease',
  lineHeight: '$0',

  '@bp2': {
    padding: '$3',
  },

  '&:focus-within': {
    backgroundColor: '$black5',
    [`${Count}`]: {
      backgroundColor: '$black10',
    },
  },

  // Because MultiSelectOption's are intended to render with no space between them,
  // The design looks weird if you are hovering on one element, and focusing an adjacent one.
  // To improve this, we tweak the focus styles when this happens, and when you are
  // Both hovering and focusing the same element. This ensures the focus style is always
  // visible, regardless of hover.
  '&:focus-within + &:hover, &:hover + &:focus-within, &:focus-within:hover': {
    backgroundColor: '$black10',
  },

  variants: {
    disabled: {
      true: {
        opacity: 0.4,
        cursor: 'not-allowed',
      },
      false: {
        '@hover': {
          '&:hover': {
            backgroundColor: '$black5',
            [`${Count}`]: {
              backgroundColor: '$black10',
            },
          },
        },

        [`${LabelText}`]: {
          '@hover': {
            '&:hover': {
              color: '$black100',
            },
          },
        },
      },
    },
  },
});
