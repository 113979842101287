import { styled } from '@f8n-frontend/stitches';

import { NFT_CARD_MIN_WIDTH } from 'design/tokens/size';

import Grid from './base/Grid';

const Root = styled(Grid, {
  gridTemplateColumns: '1fr',

  gridAutoRows: '1fr',
  gridAutoFlow: 'row dense',
  gridColumnGap: '$6',
  gridRowGap: '$8',

  '@bp0': {
    gridTemplateColumns: `repeat(auto-fill, minmax(${NFT_CARD_MIN_WIDTH}px, 1fr))`,
  },
});

const Item = styled('div', {
  variants: {
    size: {
      small: {},
      large: {
        '@bp1': {
          gridColumn: 'span 2',
          gridRow: 'span 2',
        },
      },
    },
  },

  defaultVariants: {
    size: 'small',
  },
});

const CardGrid = {
  Root,
  Item,
};

export default CardGrid;
