import { styled } from '@f8n-frontend/stitches';
import { arrayMoveImmutable } from 'array-move';
import {
  AnimatePresence,
  motion,
  MotionConfig,
  MotionConfigProps,
  PanInfo,
  useAnimationFrame,
  useDragControls,
  useMotionValue,
} from 'framer-motion';
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import CardGrid from 'components/CardGrid';
import Box from 'components/base/Box';
import Icon from 'components/base/IconV2';
import Tooltip from 'components/base/Tooltip';
import ArtworkCard, {
  ArtworkCardProps,
} from 'components/cards/artwork/ArtworkCard';
import ArtworkCardSkeletonGrid, {
  ArtworkCardSkeletons,
} from 'components/cards/artwork/ArtworkCardSkeletonGrid';
import {
  useDeleteFromHomeTabOption,
  useToggleHomeTabItemSizeOption,
} from 'components/dropdown/artwork';
import { hasToken } from 'contexts/auth/helpers';
import useAuth from 'contexts/auth/useAuth';
import useNotifications, {
  NotificationStore,
} from 'state/stores/notifications';

import DragIcon from 'assets/icons/drag-handle.svg';
import { ApiDeleteHomeItemVariables } from 'gql/api/mutations/delete-home-item.generated';
import {
  ApiUpdateHomeItemVariables,
  useUpdateHomeItem,
} from 'gql/api/mutations/update-home-item.generated';
import useSegmentEvent from 'hooks/analytics/use-segment-event';
import useAssetFallbackByArtworkIds from 'hooks/queries/hasura/artworks/use-asset-fallback-by-artwork-ids';
import useHomeTabArtworks, {
  HomeTabArtworkItem,
} from 'hooks/use-home-tab-artworks';
import {
  useHomeTabItems,
  GetHomeTabItemsVariables,
} from 'hooks/use-home-tab-items';
import useModal from 'hooks/use-modal';
import { findFallbackAsset, mapFailedArtworksToIds } from 'utils/assets';
import {
  changeHomeTabItemPosition,
  changeHomeTabItemSize,
  deleteItemByIdFromHomeTabItems,
} from 'utils/home-tab/utils';
import { isTouchDevice } from 'utils/styles';

import { UserLight } from 'types/Account';
import { ApiErrorData, getApiErrorMessage } from 'types/ApiErrors';
import { HomeTabCurationProps, HomeTabItem } from 'types/HomeTab';

import { compareAllPositions } from './drag-drop';
import { handleDragScroll } from './drag-scroll';
import { Coords, MinimalRect } from './types';

type HomeTabQueryVariables = GetHomeTabItemsVariables;

type HomeTabProps = HomeTabCurationProps & {
  onboarding: React.ReactNode;
  queryVariables: HomeTabQueryVariables;
};

export default function HomeTab(props: HomeTabProps) {
  const homeTabItemsQuery = useHomeTabItems(props.queryVariables);

  let content: JSX.Element | null = null;

  if (homeTabItemsQuery.isLoading) {
    content = <ArtworkCardSkeletonGrid />;
  }

  if (homeTabItemsQuery.data) {
    content = (
      <HomeTabArtworks
        canCurateHomeTab={props.canCurateHomeTab}
        homeTabCuration={props.homeTabCuration}
        homeTabItems={homeTabItemsQuery.data}
        onboarding={props.onboarding}
        queryVariables={props.queryVariables}
        uid={props.queryVariables.accountAddress}
      />
    );
  }

  return (
    <Box
      css={{
        flexGrow: 1,
        paddingY: '$6',
      }}
    >
      {content}
    </Box>
  );
}

type HomeTabUid = string | number;

type HomeTabArtworksProps = HomeTabCurationProps & {
  homeTabItems: HomeTabItem[];
  onboarding: React.ReactNode;
  uid: HomeTabUid;
  queryVariables: HomeTabQueryVariables;
};

function HomeTabArtworks(props: HomeTabArtworksProps) {
  const { homeTabItems, uid, onboarding, queryVariables } = props;

  const homeTabArtworksQuery = useHomeTabArtworks({
    uid,
    homeTabItems,
  });

  if (homeTabArtworksQuery.isLoading) {
    return (
      <CardGrid.Root>
        <ArtworkCardSkeletons items={homeTabItems.length} />
      </CardGrid.Root>
    );
  }

  const showOnboarding = !homeTabArtworksQuery.data && props.canCurateHomeTab;

  return (
    <AnimatePresence exitBeforeEnter initial={false}>
      {showOnboarding ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="onboarding"
        >
          {onboarding}
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          key="items"
        >
          <AnimatePresence>
            <HomeTabArtworkGrid
              artworks={homeTabArtworksQuery.data || []}
              canCurateHomeTab={props.canCurateHomeTab}
              uid={uid}
              queryVariables={queryVariables}
            />
          </AnimatePresence>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

type Size = HomeTabItem['size'];

type HomeTabArtworkGridProps = {
  artworks: HomeTabArtworkItem[];
  canCurateHomeTab: boolean;
  uid: HomeTabUid;
  queryVariables: GetHomeTabItemsVariables;
};

type OnDragOptions = {
  draggedIndex: number;
  draggedCardCoords: Coords;
  draggedCardSize: Size;
};
type OnDrag = (options: OnDragOptions) => void;
type OnDragEnd = (id: string) => void;

type OnChangeSize = (variables: ApiUpdateHomeItemVariables) => void;
type OnDeleteItem = (variables: ApiDeleteHomeItemVariables) => void;

type SetPositionOptions = {
  index: number;
  position: MinimalRect;
};
type SetPosition = (options: SetPositionOptions) => void;

type SetDraggedItemOptions = {
  id: string;
  el: HTMLDivElement;
};
type SetDraggedItem = (options: SetDraggedItemOptions | null) => void;

type HomeTabArtworkGridCallbacks = {
  onChangeSize: OnChangeSize;
  onDeleteItem: OnDeleteItem;
  onDrag: OnDrag;
  onDragEnd: OnDragEnd;
  onLayoutAnimationComplete(): void;
  onLayoutAnimationStart(): void;
  setDraggedItem: SetDraggedItem;
  setPosition: SetPosition;
};

type HomeTabArtworkCardProps = Omit<ArtworkCardProps, 'menuOptions'> &
  HomeTabArtworkGridCallbacks & {
    canCurateHomeTab: boolean;
    canReorder: boolean;
    draggedItemId: string | null;
    id: string;
    index: number;
    isDragDropEnabled: boolean;
    notifications: NotificationStore;
    size: Size;
  };

/**
 * Usually getBoundingClientRect accounts for transforms. In this case we want the "original" position,
 * before transforms are applied.
 * */
function getUntransformedBoundingClientRect(element: HTMLElement): DOMRect {
  const transform = element.style.transform;
  element.style.transform = '';
  const bounds = element.getBoundingClientRect();
  element.style.transform = transform;
  return bounds;
}

function getMinimalRect(element: HTMLElement): MinimalRect {
  const bounds = getUntransformedBoundingClientRect(element);
  return {
    width: bounds.width,
    height: bounds.height,
    x: bounds.x,
    y: bounds.y,
  };
}

const DEFAULT_COORDS: Coords = {
  x: 0,
  y: 0,
};

const MOTION_TRANSITION: MotionConfigProps['transition'] = {
  type: 'spring',
  /**
   * Strength of opposing force.
   * When zero, the spring will bounce infinitely.
   * Higher values will have less bounce.
   *
   * Framer default is 10.
   */
  damping: 10,
  /**
   * Mass of the moving object.
   * Increasing number causes more lethargic movement.
   *
   * Framer default is 1.
   */
  mass: 0.2,
  /**
   * Stiffness of the spring.
   * Increasing number causes more sudden movement.
   *
   * Framer default is 100.
   */
  stiffness: 100,
};

function HomeTabArtworkGrid(props: HomeTabArtworkGridProps) {
  const {
    artworks: initialArtworks,
    canCurateHomeTab,
    queryVariables,
    uid,
  } = props;

  const auth = useAuth();
  const modal = useModal();
  const notifications = useNotifications();
  const sendSegmentEvent = useSegmentEvent();

  const [artworks, setArtworks] = useState(initialArtworks);
  const [canReorder, setCanReorder] = useState(true);
  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);

  const draggedElRef = useRef<HTMLDivElement | null>(null);
  const dragStartTimeRef = useRef(performance.now());
  const pointerRef = useRef<Coords>({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    setArtworks(initialArtworks);
  }, [initialArtworks]);

  useEffect(() => {
    if (draggedItemId) {
      window.document.body.classList.add('dragging');
    } else {
      window.document.body.classList.remove('dragging');
    }

    return () => {
      window.document.body.classList.remove('dragging');
    };
  }, [draggedItemId]);

  useEffect(() => {
    const onPointerMove = (event: PointerEvent) => {
      pointerRef.current = {
        x: event.clientX,
        y: event.clientY,
      };
    };

    if (draggedItemId) {
      dragStartTimeRef.current = performance.now();
      window.addEventListener('pointermove', onPointerMove);
    } else {
      window.removeEventListener('pointermove', onPointerMove);
    }

    return () => {
      window.removeEventListener('pointermove', onPointerMove);
    };
  }, [draggedItemId]);

  useAnimationFrame(() => {
    if (!draggedElRef.current) return;

    handleDragScroll({
      pointerY: pointerRef.current.y,
      dragStartTime: dragStartTimeRef.current,
    });
  });

  const setDraggedItem = useCallback<SetDraggedItem>((item) => {
    if (item) {
      setDraggedItemId(item.id);
      draggedElRef.current = item.el;
    } else {
      setDraggedItemId(null);
      draggedElRef.current = null;
    }
  }, []);

  const setHomeTabItemsQueryCache = useMemo(
    () => useHomeTabItems.getDataSetter(queryVariables),
    []
  );

  const setHomeTabArtworksQueryCache = useMemo(
    () =>
      useHomeTabArtworks.getDataSetter({
        uid,
      }),
    []
  );

  const fallbackArtworkIds = mapFailedArtworksToIds(
    artworks.map((item) => item.artwork)
  );
  const fallbackAssetsQuery = useAssetFallbackByArtworkIds(
    { artworkIds: fallbackArtworkIds },
    { refetchOnWindowFocus: false }
  );

  /**
   * Grid row height is forced when there is only one LARGE item. Usually, the height of
   * the LARGE items is double the height of any SMALL items in the grid.
   *
   * Without forcing the row height, the large items lose their height.
   */
  const shouldForceGridRowHeight = artworks.every(
    (item) => item.size === 'LARGE'
  );

  const isDragDropEnabled =
    canCurateHomeTab && isTouchDevice() === false && artworks.length > 1;

  const onChangeSize = useCallback<OnChangeSize>((variables) => {
    const { id, size } = variables;
    if (!size) return;

    setArtworks((lastArtworks) => {
      return changeHomeTabItemSize(id, size, lastArtworks);
    });
    setHomeTabItemsQueryCache((lastItems) => {
      return changeHomeTabItemSize(id, size, lastItems);
    });
    setHomeTabArtworksQueryCache((lastArtworks) => {
      return changeHomeTabItemSize(id, size, lastArtworks);
    });
  }, []);

  const onDeleteItem = useCallback((variables) => {
    const { id } = variables;

    setArtworks((lastArtworks) => {
      return deleteItemByIdFromHomeTabItems(id, lastArtworks);
    });
    setHomeTabItemsQueryCache((lastItems) => {
      return deleteItemByIdFromHomeTabItems(id, lastItems);
    });
    setHomeTabArtworksQueryCache((lastArtworks) => {
      return deleteItemByIdFromHomeTabItems(id, lastArtworks);
    });
  }, []);

  const updateHomeItem = useUpdateHomeItem<ApiErrorData>({
    onMutate: (variables) => {
      if (typeof variables.position === 'number') {
        const { id, position } = variables;

        // snapshot the last known position, to enable roll-backs if the update fails
        const item = artworks.find((artwork) => artwork.id === id);
        const lastPosition = item?.position;

        setHomeTabItemsQueryCache((lastItems) => {
          return changeHomeTabItemPosition(id, position, lastItems);
        });

        setHomeTabArtworksQueryCache((lastArtworks) => {
          return changeHomeTabItemPosition(id, position, lastArtworks);
        });

        return {
          lastPosition,
        };
      }
    },
    onSuccess: () => {
      sendSegmentEvent({
        eventName: 'nft_reordered',
        payload: {
          publicKey: queryVariables.accountAddress,
          tab: 'home',
          type: 'profile',
        },
      });
    },

    onError: (error, variables, context: { lastPosition?: number }) => {
      notifications.show.error({
        message: getApiErrorMessage(error),
      });

      if (context?.lastPosition === undefined) return;
      const { lastPosition } = context;
      const { id } = variables;

      setHomeTabItemsQueryCache((lastItems) => {
        return changeHomeTabItemPosition(id, lastPosition, lastItems);
      });

      setHomeTabArtworksQueryCache((lastArtworks) => {
        return changeHomeTabItemPosition(id, lastPosition, lastArtworks);
      });
    },
  });

  const onLayoutAnimationComplete = useCallback(() => setCanReorder(true), []);
  const onLayoutAnimationStart = useCallback(() => setCanReorder(false), []);

  /** Used to store the last known drag position, to determine which direction the pointer is moving */
  const lastDragCoordRef = useRef<Coords | null>(null);

  /** Used to store the new index of the most recently moved item */
  const newIndexRef = useRef<number | null>(null);

  /** stores the coordinates and size of every grid item */
  const positionsRef = useRef<MinimalRect[]>([]);

  const setPosition = useCallback<SetPosition>((options) => {
    positionsRef.current[options.index] = options.position;
  }, []);

  const onDrag = useCallback<OnDrag>(
    (options) => {
      if (!canReorder) return;

      const { draggedIndex, draggedCardCoords, draggedCardSize } = options;

      const positions = positionsRef.current;
      const sourcePosition = positions[draggedIndex] as MinimalRect;

      const isLarge = draggedCardSize === 'LARGE';
      const lastCoords = lastDragCoordRef.current;

      const moveTo = (index: number) => {
        positionsRef.current = arrayMoveImmutable(
          positions,
          draggedIndex,
          index
        );
        setCanReorder(false);
        setArtworks((item) => arrayMoveImmutable(item, draggedIndex, index));
        newIndexRef.current = index;
      };

      if (isLarge && lastCoords) {
        const HALF_WIDTH = sourcePosition.width / 2;
        const HALF_HEIGHT = sourcePosition.height / 2;
        const SIZES = {
          width: HALF_WIDTH,
          height: HALF_HEIGHT,
        };

        const xMovement = draggedCardCoords.x - lastCoords.x;
        const yMovement = draggedCardCoords.y - lastCoords.y;

        // User is dragging to the right, or downwards
        if (xMovement > 0 || yMovement > 0) {
          if (xMovement > yMovement) {
            // In this scenario, user is dragging a large card to the right
            // We can assume they want to compare the top right corner of this card
            // to every other grid item.
            const draggedItemRect = {
              x: draggedCardCoords.x + SIZES.width,
              y: draggedCardCoords.y,
              ...SIZES,
            };

            compareAllPositions({
              positions,
              draggedIndex,
              draggedItemRect,
              onCollision: (index) => moveTo(index),
            });
          } else {
            // In this scenario, user is dragging a large card downwards
            // We can assume they want to compare the bottom left corner of this card
            // to every other grid item.
            const draggedItemRect = {
              x: draggedCardCoords.x,
              y: draggedCardCoords.y + SIZES.height,
              ...SIZES,
            };

            compareAllPositions({
              positions,
              draggedIndex,
              draggedItemRect,
              /**
               * When dragging large items downwards, the smaller items in the grid
               * will adjust to fill the newly available space caused by moving the large item.
               *
               * Given this grid structure:
               * 0 1 1 2
               * 3 1 1 4
               * 5 6 7 8
               *
               * If the user drags the large item at index 1 downwards, they will "collide" with index 6.
               * To keep the large item inside the same column, we need to offset this by one (to index 5).
               * This gives us a new structure of:
               *
               * 0 1 2 3
               * 4 5 5 6
               * 7 5 5 8
               */
              onCollision: (index) => moveTo(index - 1),
            });
          }
        } else {
          const draggedItemRect = {
            ...draggedCardCoords,
            ...SIZES,
          };

          compareAllPositions({
            positions,
            draggedIndex,
            draggedItemRect,
            onCollision: (index) => moveTo(index),
          });
        }
      } else {
        const draggedItemRect = {
          ...sourcePosition,
          ...draggedCardCoords,
        };

        compareAllPositions({
          positions,
          draggedIndex,
          draggedItemRect,
          onCollision: (index) => moveTo(index),
        });
      }

      lastDragCoordRef.current = draggedCardCoords;
    },
    [canReorder]
  );

  const onDragEnd = useCallback<OnDragEnd>(
    (id) => {
      if (typeof newIndexRef.current === 'number') {
        const mutatePosition = () => {
          updateHomeItem.mutate({
            id,
            position: newIndexRef.current,
          });
        };

        if (!hasToken(auth)) {
          // TODO: fire mutatePosition after user signs via this modal.
          modal.setModal({ type: 'AUTH_PRE_SIGN' });
        } else {
          mutatePosition();
        }
      }

      setDraggedItemId(null);
      draggedElRef.current = null;
      lastDragCoordRef.current = null;
      newIndexRef.current = null;
    },
    [auth.state]
  );

  return (
    <MotionConfig transition={MOTION_TRANSITION}>
      <CardGrid.Root
        css={{
          gridAutoRows: shouldForceGridRowHeight
            ? 472 // Matches height of the ArtworkCard
            : '1fr', // Allow the small items to set the row height
        }}
      >
        <AnimatePresence initial>
          {artworks.map((item, index) => {
            const { id } = item;

            const fallbackAsset = findFallbackAsset(
              fallbackAssetsQuery.data?.asset,
              item.artwork.id
            );

            return (
              <MemoizedHomeTabArtworkCard
                artwork={item.artwork}
                canCurateHomeTab={canCurateHomeTab}
                canReorder={canReorder}
                creator={item.artwork.creator as UserLight}
                draggedItemId={draggedItemId}
                fallbackAsset={fallbackAsset}
                id={id}
                index={index}
                isDragDropEnabled={isDragDropEnabled}
                key={id}
                notifications={notifications}
                onChangeSize={onChangeSize}
                onDeleteItem={onDeleteItem}
                onDrag={onDrag}
                onDragEnd={onDragEnd}
                onLayoutAnimationComplete={onLayoutAnimationComplete}
                onLayoutAnimationStart={onLayoutAnimationStart}
                setDraggedItem={setDraggedItem}
                setPosition={setPosition}
                size={item.size}
              />
            );
          })}
        </AnimatePresence>
      </CardGrid.Root>
    </MotionConfig>
  );
}

const MemoizedHomeTabArtworkCard = memo(function HomeTabArtworkCard(
  props: HomeTabArtworkCardProps
) {
  const {
    artwork,
    canCurateHomeTab,
    canReorder,
    draggedItemId,
    id,
    index,
    isDragDropEnabled,
    notifications,
    onChangeSize,
    onDeleteItem,
    onDrag,
    onDragEnd,
    onLayoutAnimationComplete,
    onLayoutAnimationStart,
    setDraggedItem,
    setPosition,
    size,
  } = props;

  const itemRef = useRef<HTMLDivElement>(null);

  const [isOverDragHandle, setIsOverDragHandle] = useState(false);
  const [isDragHandlePressed, setIsDragHandlePressed] = useState(false);
  const [recentlyDragged, setRecentlyDragged] = useState(false);

  const dragStatus = draggedItemId
    ? draggedItemId === id
      ? 'DRAGGING'
      : 'DRAGGING_OTHER'
    : 'IDLE';

  const toggleSizeOption = useToggleHomeTabItemSizeOption(
    {
      id,
      // when size is currently large, make it small, and vice-versa
      size: size === 'LARGE' ? 'SMALL' : 'LARGE',
    },
    {
      onMutate: (variables) => {
        onChangeSize(variables);
      },
    }
  );

  const deleteFromHomeTabOption = useDeleteFromHomeTabOption(
    { id },
    {
      onMutate: (variables) => {
        onDeleteItem(variables);
      },
      /**
       * We have to add a direct onError because running onMutate
       * overrides the inherited onError. TODO: investigate cause
       */
      onError(error) {
        notifications.show.error({
          message: getApiErrorMessage(error as ApiErrorData),
        });
      },
    }
  );

  const menuOptions = canCurateHomeTab
    ? [deleteFromHomeTabOption, toggleSizeOption]
    : [];

  /** used to track distance between the pointer, and the top-left corner of the dragged card */
  const dragHandleOffsetRef = useRef<Coords>(DEFAULT_COORDS);

  const trackPosition = () => {
    if (!itemRef.current) return;
    const rect = getMinimalRect(itemRef.current);
    if (!rect) return;
    setPosition({
      index,
      position: rect,
    });
  };
  useAnimationFrame(trackPosition);

  const dragControls = useDragControls();
  const startDrag = useCallback((event: React.PointerEvent) => {
    dragControls.start(event);
  }, []);

  let isTooltipVisible = false;
  if (
    !recentlyDragged &&
    !isDragHandlePressed &&
    isOverDragHandle &&
    canReorder
  ) {
    isTooltipVisible = true;
  }

  let mediaOverlay: ArtworkCardProps['mediaOverlay'] = undefined;
  if (isDragDropEnabled) {
    if (isOverDragHandle || dragStatus === 'DRAGGING') {
      mediaOverlay = 'always';
    } else if (dragStatus === 'DRAGGING_OTHER') {
      mediaOverlay = 'never';
    }
  }

  const lastDragScrollRef = useRef(window.scrollY);
  const yOffset = useMotionValue(0);

  return (
    <CardGrid.Item
      as={motion.div}
      size={mapSizeToCardSize(size)}
      dragControls={dragControls}
      dragListener={false}
      dragTransition={{ bounceStiffness: 200, bounceDamping: 20 }}
      dragElastic={1}
      ref={itemRef}
      css={{
        // For drag handler that gets absolutely positioned on top
        position: 'relative',
        height: '100%',
        borderRadius: '$2',
        pointerEvents: dragStatus === 'DRAGGING_OTHER' ? 'none' : 'auto',
        zIndex: dragStatus === 'DRAGGING' || recentlyDragged ? 1000 : 0,
        // Prevent drag action from selecting text in other cards
        userSelect:
          dragStatus === 'DRAGGING' || dragStatus === 'DRAGGING_OTHER'
            ? 'none'
            : 'auto',

        [`& > ${DragHandle}`]: {
          opacity: 0,
          transition: 'opacity $1 $ease, background $1 $ease',
          ...(dragStatus === 'DRAGGING'
            ? {
                background: '$black20',
                backdropFilter: 'blur(20px)',
              }
            : {}),
        },

        [`&:hover > ${DragHandle}`]: {
          opacity: 1,
        },
      }}
      onDragStart={(_event: MouseEvent, info: PanInfo) => {
        if (!itemRef.current) return;

        setRecentlyDragged(true);
        setDraggedItem({
          el: itemRef.current,
          id,
        });

        const itemRect = getMinimalRect(itemRef.current);
        const pointerRelativeToDocument = info.point;

        dragHandleOffsetRef.current = {
          x: pointerRelativeToDocument.x - itemRect.x,
          /**
           * Warning: pointer is relative to document, and itemRect is relative to viewport.
           * To account for this difference, we subtract window.scrollY.
           */
          y: pointerRelativeToDocument.y - itemRect.y - window.scrollY,
        };
        lastDragScrollRef.current = window.scrollY;
      }}
      onDrag={(_event: MouseEvent, info: PanInfo) => {
        if (!itemRef.current) return;

        const lastScroll = lastDragScrollRef.current;
        const currentScroll = window.scrollY;
        const scrollChange = currentScroll - lastScroll;

        /**
         * This patches an issue where the dragged card gets "stuck" during an auto-scroll.
         * @see https://www.loom.com/share/32df6abd02254edcac476e817db6ffb4
         * */
        if (scrollChange !== 0) {
          /**
           * When user has not moved their pointer, Framer motion does not update the transform
           * of the dragged item. You have not "dragged" or moved the card, so they don't re-calculate
           * the new transformed position.
           *
           * This is usually fine, but in our case we have other logic to auto-scroll the window when
           * dragging near the top or bottom of the viewport. When this happens, the card looks "stuck" because it doesn't
           * stay with the cursor as the window scrolls.
           * */
          if (info.delta.x === 0 && info.delta.y === 0) {
            // When the user has not moved their cursor, apply vertical transform to artwork card
            yOffset.set(scrollChange);
          } else {
            // otherwise, remove transform and reset ref to last scroll position
            yOffset.set(0);
            lastDragScrollRef.current = currentScroll;
          }
        } else {
          // if no scroll change, always reset transform
          yOffset.set(0);
        }

        const pointerRelativeToDocument = info.point;
        const dragHandleOffset = dragHandleOffsetRef.current;

        const draggedCardCoords = {
          x: pointerRelativeToDocument.x - dragHandleOffset.x,
          y: pointerRelativeToDocument.y - dragHandleOffset.y - window.scrollY,
        };

        onDrag({
          draggedIndex: index,
          draggedCardCoords: draggedCardCoords,
          draggedCardSize: size,
        });
      }}
      onDragEnd={() => {
        yOffset.set(0);
        onDragEnd(id);
        setIsOverDragHandle(false);
        setTimeout(() => {
          setRecentlyDragged(false);
        }, 2000);
      }}
      onLayoutAnimationComplete={onLayoutAnimationComplete}
      onLayoutAnimationStart={onLayoutAnimationStart}
      dragSnapToOrigin
      drag={isDragDropEnabled}
      layout
    >
      <Box
        as={motion.div}
        css={{ height: 'inherit' }}
        style={{
          /**
           * This yOffset must be applied to this internal motion.div, to avoid interfering with the transforms applied
           * to the parent when dragging.
           */
          y: yOffset,
        }}
      >
        <ArtworkCard
          artwork={artwork}
          creator={artwork.creator as UserLight}
          menuOptions={menuOptions}
          fallbackAsset={props.fallbackAsset}
          isOversized={size === 'LARGE'}
          forceRenderMedia
          mediaOverlay={mediaOverlay}
          isDragDropEnabled={isDragDropEnabled}
        />
        {isDragDropEnabled && (
          <Tooltip
            content="Drag to reorder"
            open={isTooltipVisible}
            size={0}
            placement="bottom"
          >
            <DragHandle
              onPointerEnter={() => {
                setIsOverDragHandle(true);
              }}
              onPointerDown={(event) => {
                startDrag(event);
                setIsDragHandlePressed(true);
              }}
              onPointerUp={() => {
                setIsDragHandlePressed(false);
              }}
              onPointerLeave={() => setIsOverDragHandle(false)}
            >
              <Icon icon={DragIcon} size={2} />
            </DragHandle>
          </Tooltip>
        )}
      </Box>
    </CardGrid.Item>
  );
});

const DragHandle = styled('div', {
  position: 'absolute',
  right: '$3',
  top: '$3',
  padding: '$4',
  color: '$white100',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '$round',
  cursor: 'grab',

  '&:active': {
    cursor: 'grabbing',
  },

  '&:hover': {
    background: '$black20',
    backdropFilter: 'blur(20px)',
  },

  '& > svg': {
    pointerEvents: 'none',
  },
});

const mapSizeToCardSize = (size: Size) =>
  size === 'LARGE' ? 'large' : 'small';
