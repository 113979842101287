import { styled } from '@f8n-frontend/stitches';

import Text from 'components/base/Text';

const Label = styled(Text, {
  cursor: 'pointer',
});

Label.defaultProps = {
  weight: 'semibold',
  size: { '@initial': 3, '@bp1': 4 },
};

export default Label;
