import { styled } from '@f8n-frontend/stitches';

import { formatNumber } from 'utils/formatters';

// TODO: extract to a reusable Badge/Tab component
const ArtworkCardBadge = styled('div', {
  padding: '5px 10px',
  borderRadius: '$round',
  color: '$black80',
  fontSize: '$1',
  fontWeight: '$semibold',
  border: '1px solid $black10',
  lineHeight: '$0',
});

type ArtworkCardStackBadgeProps = {
  itemCount: number;
};

export default function ArtworkCardStackBadge(
  props: ArtworkCardStackBadgeProps
) {
  const { itemCount } = props;

  return <ArtworkCardBadge>{formatNumber(itemCount)} NFTs</ArtworkCardBadge>;
}
