import { keyframes } from '@stitches/react';

import CardGrid from 'components/CardGrid';
import Box from 'components/base/Box';

import { MarketAvailability } from 'types/MarketAvailability';

import ArtworkCardSkeleton, {
  ArtworkCardSkeletonVariant,
} from './ArtworkCardSkeleton';

interface ArtworkCardSkeletonsProps {
  animated?: boolean;
  items?: number;
  variant?: ArtworkCardSkeletonVariant;
}

const pulse = keyframes({
  '0%': {
    opacity: 0.6,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.6,
  },
});

export function ArtworkCardSkeletons(props: ArtworkCardSkeletonsProps) {
  const { animated = false, items = 8, variant } = props;
  return (
    <>
      {[...Array(Math.ceil(Math.min(items, 8)))].map((_, index) => (
        <Box
          key={index}
          css={
            animated
              ? {
                  animation: `${pulse} 1s ease-in-out infinite`,
                  animationDelay: `${index * 0.2}s`,
                }
              : {}
          }
        >
          <ArtworkCardSkeleton variant={variant} />
        </Box>
      ))}
    </>
  );
}

export default function ArtworkCardSkeletonGrid(
  props: ArtworkCardSkeletonsProps
) {
  return (
    <CardGrid.Root>
      <ArtworkCardSkeletons {...props} />
    </CardGrid.Root>
  );
}

export const mapMarketAvailabilityToSkeletonVariant = (
  marketAvailability: MarketAvailability | null
): ArtworkCardSkeletonVariant | undefined => {
  switch (marketAvailability) {
    case 'LIVE_AUCTION':
      return 'auction';
    case 'HAS_ACTIVE_BUY_NOW':
    case 'RESERVE_NOT_MET':
      return 'price';
    case 'HAS_ACTIVE_OFFER':
      return 'offer';
    default:
      return undefined;
  }
};
